import styled from "styled-components";
import { hexToRgbA } from "../../../../../../../../../../../../utils";

export const QueryBlockResultSectionStyled = styled.span<{
  isChatPage?: boolean;
  isZeroMargin?: boolean;
}>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: ${({ isChatPage, isZeroMargin }): string =>
    isChatPage ? "3px" : isZeroMargin ? "0" : "7px"};
  margin-bottom: ${({ isChatPage, isZeroMargin }): string =>
    isChatPage ? "unset" : isZeroMargin ? "0" : "10px"};
  word-break: break-all;
  width: 100%;
`;

export const UsedContextsListStyled = styled.div`
  margin-top: 12px;

  .contexts-list-wrapper {
    color: ${({ theme }): string =>
      hexToRgbA(theme?.textColors?.BLACK_COLOR, 0.85)};
  }
`;
