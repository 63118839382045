import { cloneElement, useCallback, useMemo, useState } from "react";
import { Legend } from "recharts";
import { calculateElementSize, numberFormatter, toFixed } from "../../../utils";
import { LegendWrapperStyled, ListItemStyled } from "./recharthoc.styles";

import {
  LegendItemType,
  RechartHOCEmbedProps,
  RechartHOCProps,
} from "./recharthoc.types";

const RechartHOC = ({
  children,
  dataKeys,
  chartType,
  legendVerticalAlignment,
  legendHorizontalAlignment,
  yAxis = [],
  showLedgends = true,
}: RechartHOCProps): JSX.Element => {
  const [inActiveLegends, setInActiveLegends] = useState<
    RechartHOCEmbedProps["inActiveLegends"]
  >({});

  const [chartYAxisLeftOffset, setChartYAxisLeftOffset] = useState<number>(70);

  const onDomainLoad = useCallback((maxVal) => {
    const widthOfMAxVal = calculateElementSize(`${maxVal}`, {
      fontSize: "14px",
    })?.clientWidth;
    setChartYAxisLeftOffset(widthOfMAxVal);
  }, []);

  const onClickLegend = useCallback((props) => {
    setInActiveLegends((st) => ({
      ...st,
      [props?.value]: !st?.[props?.value],
    }));
  }, []);

  const renderLegend = useCallback(
    (props): JSX.Element => {
      const { payload } = props;

      return (
        <LegendWrapperStyled className="legend-wrapper">
          {payload?.map((entry: LegendItemType, index: number) => (
            <ListItemStyled
              legendsCount={payload?.length || 0}
              title={entry?.value}
              onClick={(): void => onClickLegend(entry)}
              inactive={!!inActiveLegends?.[entry?.value?.toString()]}
              color={entry?.color || ""}
              key={`item-${index}`}
            >
              <span className="list-item-icon" />

              <span className="chart-legend">{entry?.value}</span>
            </ListItemStyled>
          ))}
        </LegendWrapperStyled>
      );
    },
    [inActiveLegends]
  );

  const legends = useMemo(
    () =>
      showLedgends && (
        <Legend
          align={legendHorizontalAlignment || "left"}
          verticalAlign={legendVerticalAlignment || "top"}
          onClick={onClickLegend}
          content={renderLegend}
          payload={dataKeys?.map((item) => ({
            value: item?.dataKey,
            color: item?.fill,
            type: "square",
            inactive: !!inActiveLegends?.[item?.dataKey?.toString()],
            count: item?.count,
            percent: item?.percent,
          }))}
          wrapperStyle={{ paddingBottom: "20px" }}
        />
      ),
    [dataKeys, inActiveLegends, showLedgends]
  );

  const { isMultipleYAxis, updatedYAxis } = useMemo(() => {
    const filteredYAxis = yAxis?.filter(
      (item) => item?.yAxisId && !inActiveLegends?.[item?.yAxisId?.toString()]
    );

    return {
      isMultipleYAxis: filteredYAxis?.length > 1,
      updatedYAxis: filteredYAxis,
    };
  }, [yAxis, inActiveLegends]);

  return cloneElement(children, {
    clonedDataKeys: dataKeys?.filter(
      (item) => !inActiveLegends?.[item?.dataKey?.toString()]
    ),
    legends,
    inActiveLegends,
    isMultipleYAxis,
    updatedYAxis,
    chartYAxisLeftOffset,
    onDomainLoad,
  } as RechartHOCEmbedProps);
};

export default RechartHOC;
