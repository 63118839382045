import { DataNode } from "antd/lib/tree";
import { Key } from "react";

import {
  constructTreeWithKeys,
  constructTreeAfterTransfer,
  getChildrenKeys,
} from "../../components/treetransfer/treetransfer.utils";

import {
  DomainDataNodeType,
  SourceDataNodeType,
} from "../../parsers/treetransferparser/treetransferparser.types";

import {
  DvSumAccessTypesIds,
  EnabledObjectType,
  NodeAccessType,
  ParsedEnabledObjectType,
} from "../../parsers/userroles/userrolesparser.types";

import { isEqual, getDvSumInformativeText } from "../../utils";

export const appendLevelOfAccessInTreeData = (
  enabledObjects: ParsedEnabledObjectType[],
  data: DataNode[]
): DataNode[] => {
  const traverseNode = (node: DataNode): DataNode => {
    const traversedChildren: DataNode[] = node?.children
      ? node?.children?.map(traverseNode)
      : [];

    const currentEnabledObject = enabledObjects?.find(
      (item) => item?.key === node?.key
    );

    return {
      ...node,
      levelOfAccess: currentEnabledObject?.node_access || "",
      children: traversedChildren?.length > 0 ? traversedChildren : [],
    } as DataNode;
  };

  return data?.map(traverseNode);
};

export const getSourceAndDestinationDataWithEnabledObjects = (
  enabledObjects: ParsedEnabledObjectType[],
  treeData: DataNode[]
): {
  treeData: DataNode[];
  sourceData: DataNode[];
  destinationData: DataNode[];
} => {
  if (enabledObjects?.length) {
    const enabledKeys: Key[] = [];

    enabledObjects?.forEach((object) => {
      if (object?.node_type === "SRC") {
        const findedSource = treeData?.find(
          (treeSource) => treeSource?.key === object?.key
        );

        if (!object?.schema_keys) {
          enabledKeys?.push(
            object?.key,
            ...getChildrenKeys(findedSource?.children || [])
          );
        } else {
          const enabledSchemaKeys: Key[] = object?.schema_keys?.split(",");
          const enabledSchemas = enabledSchemaKeys?.map(
            (schemaKey) =>
              findedSource?.children?.find(
                (findedSourceSchema) => findedSourceSchema?.key === schemaKey
              ) || ({} as DataNode)
          );

          enabledKeys?.push(
            object?.key,
            ...getChildrenKeys(enabledSchemas || [])
          );
        }
      }

      if (object?.node_type === "TBL" || object?.node_type === "DSR") {
        enabledKeys?.push(
          object?.key,
          object?.schema_keys || "",
          object?.source_key || ""
        );
      }

      if (object?.node_type === "DMN") {
        const findedDomain = treeData?.find(
          (treeDomain) => treeDomain?.key === object?.key
        );

        enabledKeys?.push(
          object?.key,
          ...getChildrenKeys(findedDomain?.children || [])
        );
      }

      if (object?.node_type === "CAT") {
        enabledKeys?.push(object?.key, object?.domain_key || "");
      }

      // GET VERIFY FROM BADER

      if (object?.node_type === "USR") {
        enabledKeys?.push(object?.key, "user-data" || "");
      }

      // GET VERIFY FROM BADER
      if (object?.node_type === "UGP") {
        enabledKeys?.push(object?.key, "group-data" || "");
      }
    });

    const uniqueEnabledKeys = [...new Set(enabledKeys)];
    const destinationTree = constructTreeWithKeys(uniqueEnabledKeys, treeData);

    return {
      treeData: appendLevelOfAccessInTreeData(enabledObjects, treeData),
      destinationData: appendLevelOfAccessInTreeData(
        enabledObjects,
        destinationTree
      ),
      sourceData: constructTreeAfterTransfer(uniqueEnabledKeys, treeData),
    };
  }

  return {
    treeData,
    sourceData: treeData,
    destinationData: [],
  };
};

export const getEnabledObjectsFromDestinationData = (
  type: "SRC" | "DMN",
  destinationData: Array<SourceDataNodeType | DomainDataNodeType>,
  treeData?: DataNode[]
): Array<EnabledObjectType> => {
  if (destinationData?.length && treeData?.length) {
    if (type === "SRC") {
      const enabledObjectsList: EnabledObjectType[] = [];

      destinationData?.forEach((destinationSource) => {
        const matchedSourceInTreeData = treeData?.find(
          (treeSource) => treeSource?.key === destinationSource?.key
        );

        const isAllTablesSelected = isEqual(
          getChildrenKeys(destinationSource?.children as DataNode[]),
          getChildrenKeys(matchedSourceInTreeData?.children as DataNode[])
        );

        const selectedSchemas = destinationSource?.children?.filter(
          (destinationSchema) => {
            const matchedSchemaInTreeData = matchedSourceInTreeData?.children?.find(
              (treeSchema) => treeSchema?.key === destinationSchema?.key
            );

            return isEqual(
              getChildrenKeys(destinationSchema?.children || []),
              getChildrenKeys(matchedSchemaInTreeData?.children || [])
            );
          }
        );

        if (isAllTablesSelected || selectedSchemas?.length) {
          const isAddSchemasList =
            !isAllTablesSelected && selectedSchemas?.length;

          enabledObjectsList?.push({
            node_id: Number(destinationSource?.id || "0"),
            node_type: "SRC",
            node_access:
              destinationSource?.levelOfAccess || ("" as NodeAccessType),
            additional_info: {
              node_key: (destinationSource?.key || "") as string,
              schema_names: isAddSchemasList
                ? selectedSchemas
                    ?.map((selectedSchema) => selectedSchema?.title)
                    ?.join(",") || ""
                : "",
              schema_keys: isAddSchemasList
                ? selectedSchemas
                    ?.map((selectedSchema) => selectedSchema?.key)
                    ?.join(",") || ""
                : "",
            },
          });
        }

        if (!isAllTablesSelected) {
          destinationSource?.children?.forEach((destinationSchema) => {
            const isSelectedSchema = selectedSchemas?.some(
              (selectedSchema) => selectedSchema?.key === destinationSchema?.key
            );

            if (!isSelectedSchema) {
              destinationSchema?.children?.forEach((destinationTable: any) => {
                enabledObjectsList?.push({
                  node_id: Number(destinationTable?.id || "0"),
                  node_type: destinationTable?.nodeType,
                  node_access:
                    destinationTable?.levelOfAccess || ("" as NodeAccessType),
                  additional_info: {
                    node_key: (destinationTable?.key || "") as string,
                    schema_keys: (destinationSchema?.key || "") as string,
                    source_key: (destinationSource?.key || "") as string,
                  },
                });
              });
            }
          });
        }
      });

      return enabledObjectsList;
    }

    const enabledSubDomainsList: EnabledObjectType[] = [];

    destinationData?.forEach((destinationDomain) => {
      const matchedDomainInTreeData = treeData?.find(
        (treeDomain) => treeDomain?.key === destinationDomain?.key
      );

      const isAllSubDomainsSelected = isEqual(
        getChildrenKeys(destinationDomain?.children as DataNode[]),
        getChildrenKeys(matchedDomainInTreeData?.children as DataNode[])
      );

      if (isAllSubDomainsSelected) {
        enabledSubDomainsList?.push({
          node_id: Number(destinationDomain?.id || "0"),
          node_type: "DMN",
          node_access:
            destinationDomain?.levelOfAccess || ("" as NodeAccessType),
          additional_info: {
            node_key: (destinationDomain?.key || "") as string,
          },
        });
      } else {
        destinationDomain?.children?.forEach((destinationSubDomain: any) => {
          enabledSubDomainsList?.push({
            node_id: Number(destinationSubDomain?.id || "0"),
            node_type: "CAT",
            node_access:
              destinationSubDomain?.levelOfAccess || ("" as NodeAccessType),
            additional_info: {
              node_key: (destinationSubDomain?.key || "") as string,
              domain_key: (destinationDomain?.key || "") as string,
            },
          });
        });
      }
    });

    return enabledSubDomainsList;
  }

  return [];
};

export const adminAccessTypeDescMapping = (id: DvSumAccessTypesIds): string => {
  const {
    add_usr_rl_mdl_adm_prm_no_adm: addUsrRlMdlAdmPrmNoAdm,
    add_usr_rl_mdl_adm_prm_usr_adm: addUsrRlMdlAdmPrmUsrAdm,
    add_usr_rl_mdl_adm_prm_act_adm: addUsrRlMdlAdmPrmActAdm,
  } = getDvSumInformativeText();

  const accessTypesDescMapping: {
    [key in DvSumAccessTypesIds]?: string;
  } = {
    NOA: addUsrRlMdlAdmPrmNoAdm?.description || "",
    USR: addUsrRlMdlAdmPrmUsrAdm?.description || "",
    ACT: addUsrRlMdlAdmPrmActAdm?.description || "",
  };

  return accessTypesDescMapping?.[id] || "";
};

export const catalogAccessTypeDescMapping = (
  id: DvSumAccessTypesIds
): string => {
  const {
    add_usr_rl_mdl_ctg_prm_no_acs: addUsrRlMdlCtgPrmNoAcs,
    add_usr_rl_mdl_ctg_prm_vwr: addUsrRlMdlCtgPrmVwr,
    add_usr_rl_mdl_ctg_prm_edt: addUsrRlMdlCtgPrmEdt,
    add_usr_rl_mdl_ctg_prm_adm: addUsrRlMdlCtgPrmAdm,
  } = getDvSumInformativeText();

  const accessTypesDescMapping: {
    [key in DvSumAccessTypesIds]?: string;
  } = {
    NOA: addUsrRlMdlCtgPrmNoAcs?.description || "",
    VWR: addUsrRlMdlCtgPrmVwr?.description || "",
    EDT: addUsrRlMdlCtgPrmEdt?.description || "",
    ADM: addUsrRlMdlCtgPrmAdm?.description || "",
  };

  return accessTypesDescMapping[id] || "";
};

export const glossaryAccessTypeDescMapping = (
  id: DvSumAccessTypesIds
): string => {
  const {
    add_usr_rl_mdl_gls_prm_no_acs: addUsrRlMdlGlsPrmNoAcs,
    add_usr_rl_mdl_gls_prm_vwr: addUsrRlMdlGlsPrmVwr,
    add_usr_rl_mdl_gls_prm_edt: addUsrRlMdlGlsPrmEdt,
    add_usr_rl_mdl_gls_prm_adm: addUsrRlMdlGlsPrmAdm,
  } = getDvSumInformativeText();

  const accessTypesDescMapping: {
    [key in DvSumAccessTypesIds]?: string;
  } = {
    NOA: addUsrRlMdlGlsPrmNoAcs?.description || "",
    VWR: addUsrRlMdlGlsPrmVwr?.description || "",
    EDT: addUsrRlMdlGlsPrmEdt?.description || "",
    ADM: addUsrRlMdlGlsPrmAdm?.description || "",
  };

  return accessTypesDescMapping[id] || "";
};
