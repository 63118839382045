export const getDataSourceAssetUrl = (sourceTypeId: string): string => {
  const assetMap: { [key: string]: string } = {
    AS3: "/assets/icons/s3/source-logo-S3.png",
    ADL: "/assets/icons/azure-data-lake/source-logo-ADL.png",
    ASQ: "/assets/icons/azuresql/source-logo-ASQ.png",
    DBS: "/assets/icons/data-bricks/source-logo-DBS.png",
    DB2: "/assets/icons/ibmdb/source-logo-DB2.png",
    EXL: "/assets/icons/file-upload/Source-logo-File-upload.png",
    MDB: "/assets/icons/mongodb/mongodb_2.png",
    MSQ: "/assets/icons/mysql/source-logo-MSQ.png",
    ORC: "/assets/icons/oracle/source-logo-ORC.png",
    PSQ: "/assets/icons/postgresql/source-logo-named-PSQ.png",
    PBI: "/assets/icons/power-bi/source-logo-PBI.png",
    RST: "/assets/icons/restapisource/source-logo-RST-large.png",
    SLF: "/assets/icons/salesforce/source-logo-SLF-small.png",
    SNF: "/assets/icons/snowflake/source-logo-SNF.png",
    MSS: "/assets/icons/sqlserver/source-logo-MSS.png",
    SNP: "/assets/icons/synapse/source-logo-SNP.png",
    TBU: "/assets/icons/tableau/source-logo-TBU.png",
  };

  return assetMap?.[sourceTypeId] || "";
};
