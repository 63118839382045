import { useState } from "react";
import { Card } from "antd";
import {
  NameType,
  ValueType,
} from "recharts/types/component/DefaultTooltipContent";

import {
  PieChart as Chart,
  ResponsiveContainer,
  Pie,
  Tooltip,
  TooltipProps,
} from "recharts";
import RechartHOC from "../recharthoc/recharthoc";

import { PIECHART_VALUE_DATA_KEY } from "../../../constants";

import { PieChartStyled } from "./piechart.styles";

import { RechartHOCEmbedProps } from "../recharthoc/recharthoc.types";
import { PieChartProps } from "./piechart.types";

import { numberFormatter, toFixed } from "../../../utils";

import { CustomTooltipStyled } from "../columnchart/column.styles";
import { redirectCircledIcon } from "../../../svgs";
import LinkButton from "../../linkbutton";
import { onBarClickRouteNames } from "../../widgetcard/widgetcard.util";

const RADIAN = Math.PI / 180;

const PieChartContent = (
  props: PieChartProps & RechartHOCEmbedProps
): JSX.Element => {
  const {
    width = 300,
    height = 300,
    data = [],
    inActiveLegends,
    legends,
    innerRadius = 90,
    onBarClick,
    showLedgends,
    clonedDataKeys,
    dataKey = "Count",
    tooltipLabelFormatter,
    isButtonInTooltip = false,
    govViewCategory,
    metricFields = [],
    ...res
  } = props;

  const [payload, setPayload] = useState<{ y: number; x: number } | undefined>({
    y: 0,
    x: 0,
  });
  const [trigger, setTrigger] = useState(false);

  const clonedData = data?.map((val) => {
    return {
      ...val,
      ...(Number(val?.[metricFields[0]?.field_name || ""]) === 0
        ? {
            [metricFields[0]?.field_name || ""]: 2,
            isZero: true,
          }
        : {}),
    };
  });

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>): JSX.Element => {
    if (active && payload && payload.length) {
      return (
        <CustomTooltipStyled
          onMouseEnter={(): void => setTrigger(true)}
          onMouseLeave={(): void => setTrigger(false)}
        >
          <div className="label-text">
            <p className="label">{`${label || payload?.[0]?.name || ""}`}</p>
            {onBarClick && (
              <LinkButton
                className="tooltip-button"
                onClick={(e): void => {
                  onBarClick?.(label || payload?.[0]?.name);
                  e.stopPropagation();
                }}
                tooltipProps={{
                  title: `You will be redirected to the ${
                    onBarClickRouteNames[govViewCategory || ""]
                  } with this filter applied by default.`,
                  placement: "top",
                }}
              >
                {redirectCircledIcon("15px", "14px")}
              </LinkButton>
            )}
          </div>

          <div className="content">
            {payload.map((pld, index) => (
              <div key={index} className="content-row">
                <div
                  style={{ color: pld.color || pld?.payload?.fill }}
                >{`${pld.dataKey}: `}</div>

                <div style={{ color: pld.color || pld?.payload?.fill }}>
                  {pld?.payload?.isZero
                    ? 0
                    : numberFormatter(
                        String(Number.isNaN(pld.value) ? 0 : Number(pld.value)),
                        true
                      )}
                </div>
              </div>
            ))}
          </div>
        </CustomTooltipStyled>
      );
    }

    return <div />;
  };

  return (
    <Card>
      <PieChartStyled>
        <ResponsiveContainer width={width} height={height}>
          <Chart data={clonedData} {...res}>
            <Pie
              data={clonedData?.filter(
                (item) => !inActiveLegends?.[item?.name?.toString() || ""]
              )}
              labelLine={false}
              dataKey={dataKey}
              innerRadius={innerRadius}
              label={false}
              paddingAngle={0}
              cursor="pointer"
              onMouseEnter={(props): void => {
                setPayload(props?.tooltipPosition);
                setTrigger(true);
              }}
              onMouseLeave={(): void => {
                setPayload(undefined);
                setTrigger(false);
                // console.log(props, "props");
              }}
            />

            <Tooltip
              wrapperStyle={{
                pointerEvents: "auto",
              }}
              trigger={
                isButtonInTooltip ? (trigger ? "click" : "hover") : "hover"
              }
              content={<CustomTooltip />}
              formatter={(val): string => numberFormatter(`${val}`)}
              labelFormatter={tooltipLabelFormatter}
            />
            {legends}
          </Chart>
        </ResponsiveContainer>
      </PieChartStyled>
    </Card>
  );
};

const PieChart = (props: PieChartProps): JSX.Element => (
  <RechartHOC
    dataKeys={props?.dataKeys}
    chartType="PIE"
    showLedgends={props?.showLedgends}
    legendVerticalAlignment={props?.legendVerticalAlignment}
    legendHorizontalAlignment={props?.legendHorizontalAlignment}
  >
    <PieChartContent {...props} />
  </RechartHOC>
);

export default PieChart;
