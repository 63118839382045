import styled from "styled-components";

export const BannerStyled = styled.div`
  height: 44.8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }): string => theme?.textColors?.WHITE_COLOR};
  background: ${({ theme }): string => theme?.bgColors?.GREY_40};
`;
