import { getDvSumInformativeText } from "../../../../utils";

import { SchemaType, SectionField } from "../../dynamicform/dynamicform.types";
import { AddCrossSystemFormConfigProps } from "../addcrosssytemform.types";

export const addOrphanKeyFormConfig = (
  props: AddCrossSystemFormConfigProps
): SchemaType => {
  const {
    sourceSelectOptions,
    columnSelectOptions,
    onSourceChange,
    onTableChange,
    tablesSelectOptions,
    selectedTableFields,
    existingFilters,
    existingRefFilters,
    isEdit,
  } = props;

  const {
    dat_dict_dat_qual_add_new_rle_crs_sys_col: {
      description: datDictDatQualAddNewRleCrsSysCol = "",
    } = {},
    dat_dict_dat_qual_add_new_rle_crs_sys_ref_col: {
      description: datDictDatQualAddNewRleCrsSysRefCol = "",
    } = {},
    dat_dict_dat_qual_add_new_rle_crs_sys_flt: {
      description: datDictDatQualAddNewRleCrsSysFlt = "",
    } = {},
    dat_dict_dat_qual_add_new_rle_crs_sys_ref_flt: {
      description: datDictDatQualAddNewRleCrsSysRefFlt = "",
    } = {},
  } = getDvSumInformativeText();

  return [
    ...((!isEdit && [
      {
        id: "basic",
        type: "section",
        fields: [
          {
            id: "rule_type",
            type: "select",
            name: "rule_type",
            label: "Rule Type",
            required: true,
            disabled: true,
          },
          {
            id: "rule_description",
            type: "text_area",
            name: "rule_description",
            label: "Rule Description",
            placeholder: "Description",
            description: "",
          },
        ],
      } as SectionField,
    ]) ||
      []),
    {
      id: "basic_input",
      type: "section",
      title: "Basic Input",
      fields: [
        {
          id: "columns",
          type: "select",
          name: "columns",
          label: "Column(s)",
          required: true,
          options: columnSelectOptions,
          description: datDictDatQualAddNewRleCrsSysCol,
          placeholder: "Select one or more",
          multiSelect: true,
        },
        {
          id: "filter_criteria",
          type: "filter",
          name: "filter_criteria",
          label: "Filter Condition",
          description: datDictDatQualAddNewRleCrsSysFlt,
          existingFilters,
        },
      ],
    },
    {
      id: "reference_input",
      type: "section",
      title: "Reference Input",
      fields: [
        {
          id: "reference_source",
          type: "select",
          name: "reference_source",
          label: "Reference Source",
          required: true,
          options: sourceSelectOptions,
          placeholder: "Select",
          showSearch: true,
          onChange: onSourceChange,
        },
        {
          id: "reference_table",
          type: "select",
          name: "reference_table",
          label: "Reference Table",
          required: true,
          options: tablesSelectOptions?.options,
          isLoadingFieldData: tablesSelectOptions?.isLoading,
          showSearch: true,
          placeholder: "Select",
          onChange: onTableChange,
        },
        {
          id: "reference_columns",
          type: "select",
          name: "reference_columns",
          label: "Reference Column(s)",
          required: true,
          options: selectedTableFields?.options,
          isLoadingFieldData: selectedTableFields?.isLoading,
          showSearch: true,
          description: datDictDatQualAddNewRleCrsSysRefCol,
          placeholder: "Select one or more",
          multiSelect: true,
        },
        {
          id: "reference_filter_criteria",
          type: "filter",
          name: "reference_filter_criteria",
          label: "Reference Filter Condition",
          description: datDictDatQualAddNewRleCrsSysRefFlt,
          existingFilters: existingRefFilters,
        },
      ],
    },
  ];
};
