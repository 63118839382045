// <--- Library Imports Start --->
import { AgGridReact } from "ag-grid-react";
import { useCallback, useEffect, useMemo, useRef } from "react";
// <--- Library Imports End --->

// <--- Components Start --->
import AgGridPagination from "./aggridpagination/aggridpagination";
// <--- Components End --->

// <--- Constants Start --->
import { CENTER_ALIGN_HEADER_CELL_CLS_NAME } from "./aggridtable.constants";
// <--- Constants End --->

// <--- Custom Hooks Start --->
import {
  useWindowSize,
  useGetAppState,
  useContainerWidth,
} from "../../customhooks";
// <--- Custom Hooks End --->

// <--- Renderer Start --->
import { DefaultRendrerForEllipsis } from "./aggrid.rendrer";
// <--- Renderer End --->

// <--- Styles Start --->
import { AgGridStyled } from "./aggridtable.styles";
// <--- Styles End --->

// <--- SVGs Start --->
import { gridSortAscIconStr, gridSortDescIconStr } from "../../svgs";
// <--- SVGs End --->

// <--- Types Start --->
import { TableProps } from "./aggridtable.types";
import CustomCountPagination from "../customcountpagination/customcountpagination";
// <--- Types End --->

function AgGridTable<T>({
  onGridReady,
  theme = "ag-theme-alpine",
  debounce = 0,
  onPaginationChanged,
  onPageSizeChange,
  pageSizes = ["5", "10", "20", "50"],
  tableColumns,
  cellRenderers,
  isTableWidthfitTowindowSize = true,
  pagination = true,
  pageSize = 10,
  rowData,
  gridRef = undefined,
  isComputeCol = true,
  showDefaultPagination = true,
  showOnlyCountPagination = false,
  ...props
}: TableProps<T>): JSX.Element {
  const tableRef = useRef<any>(null);

  const [windowWidth] = useWindowSize(debounce);
  const { width: containerWidth, ref } = useContainerWidth(debounce);
  const { sideBarPosition } = useGetAppState();

  useEffect(() => {
    if (tableRef?.current) {
      isTableWidthfitTowindowSize && tableRef?.current?.sizeColumnsToFit();
    }
  }, [windowWidth, containerWidth, tableRef, sideBarPosition]);

  const onInnerPaginationChanged = useCallback(
    (event): void => {
      onPaginationChanged && onPaginationChanged(event);
    },
    [tableRef]
  );

  const onChangeGridReady = useCallback(
    (params) => {
      tableRef.current = params?.api;
      isTableWidthfitTowindowSize && params?.api?.sizeColumnsToFit();

      onGridReady && onGridReady(params);
    },
    [tableRef]
  );

  const isEmpty = tableRef?.current?.getDisplayedRowCount() === 0;

  useEffect(() => {
    if (tableRef?.current) {
      if (tableRef?.current?.getDisplayedRowCount() === 0) {
        tableRef?.current?.showNoRowsOverlay();
      } else {
        tableRef?.current?.hideOverlay();
      }
    }
  }, [isEmpty]);

  const columns = useMemo(() => {
    return isComputeCol
      ? tableColumns?.map((column) => ({
          ...(column?.field === "action" && {
            pinned: "right",
            minWidth: 50,
            maxWidth: 80,
          }),
          ...column,
          sortable: column?.sortable ?? true,
          cellRenderer:
            cellRenderers && cellRenderers?.[column?.field]
              ? cellRenderers[column.field]?.render
              : column?.cellRenderer || "DefaultRendrerForEllipsis",
          cellRendererParams:
            cellRenderers &&
            cellRenderers?.[column?.field] &&
            cellRenderers[column.field]?.params,
          headerClass: column?.isTextCenterAlign
            ? column?.headerClass || CENTER_ALIGN_HEADER_CELL_CLS_NAME
            : column?.headerClass || "",
        }))
      : tableColumns;
  }, [tableColumns, cellRenderers, isComputeCol]);

  return (
    <AgGridStyled
      className={theme}
      ref={ref}
      maxHeight={props?.maxHeight}
      isEmpty={tableRef?.current?.paginationGetRowCount() <= 0}
    >
      <AgGridReact
        animateRows
        localeText={{
          noRowsToShow: "No Records to View",
        }}
        {...props}
        frameworkComponents={{
          ...props?.frameworkComponents,
          DefaultRendrerForEllipsis,
        }}
        ref={gridRef}
        onGridReady={onChangeGridReady}
        rowData={rowData}
        paginationPageSize={pageSize}
        columnDefs={columns as any}
        onPaginationChanged={onInnerPaginationChanged}
        pagination={pagination}
        domLayout="autoHeight"
        sortingOrder={["asc", "desc", null]}
        suppressCellSelection={props?.suppressCellSelection || true}
        icons={{
          sortAscending: gridSortAscIconStr,
          sortDescending: gridSortDescIconStr,
        }}
        suppressPaginationPanel
      />
      {pagination && showDefaultPagination && !showOnlyCountPagination && (
        <AgGridPagination
          gridApi={tableRef?.current}
          pageSizes={pageSizes}
          onPageSizeChange={onPageSizeChange}
        />
      )}
      {showOnlyCountPagination && (
        <CustomCountPagination gridApi={tableRef?.current} />
      )}
    </AgGridStyled>
  );
}
export default AgGridTable;
