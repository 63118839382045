import styled from "styled-components";

export const PieChartStyled = styled.div`
  width: 100%;
  .ant-card {
    .ant-card-body {
      padding: 0px;
    }
  }
`;
