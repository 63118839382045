// <--- Library Imports Start --->
import { AxiosResponse } from "axios";
// <--- Library Imports End --->

// <--- Constants Start --->
import { ANALYSIS_SECTION_TITLES } from "../../constants/labelconstants/analysisconfig";

import { DEFAULT_QUERY_BLOCK_GRID_PAGINATION } from "../../pages/analysisdetailpage/analysisdetalpage.constants";
// <--- Constants End --->

// <--- Types Start --->
import {
  AnalysisDetailsDocResponse,
  AnalysisDocResponse,
  ChatBotsResponseType,
  DatasetsResponseType,
  ReturnTypeOfAllDatasets,
  ReturnTypeOfChatBots,
  ReturnTypeOfAnalysisDetailPageParser,
  ReturnTypeOfAnalysisRefParser,
  UserType,
  ResponseQueryFiltersForUIType,
  TableType,
  AnalysisTopicsListResponseType,
  ReturnTypeOfAnalysisTopicsList,
  ChatListResponseType,
  ReturnTypeOfChatList,
  ReturnTypeOfParseFieldsOfTablesThatIncludesInPrompt,
  PinnedTablesFieldsRes,
  ReturnTypeOfUserPreferencesParser,
  UserPreferencesResponseType,
  ReturnTypeOfBotConceptsParser,
  BotConceptsResponseType,
  AnalysisQueriesResponseType,
  ReturnTypeOfAnalysisQueriesParser,
  AnalysisQueryDetailResponseType,
  ReturnTypeOfReviewCaddiFeedbacksListParser,
  ReviewCaddiFeedbacksListResponseType,
} from "./analysisparser.types";

import {
  AnalysisPageQueryBox,
  AnalysisPageQueryBoxChartConfig,
  ContextType,
} from "../../pages/analysisdetailpage/analysisdetailpage.types";
// <--- Types End --->

// <--- Utils Start --->
import {
  checkIsViewOnlyChatQuestionPage,
  checkSourceType,
  createUUID,
  flattenObjects,
  getObjectEntries,
  getObjectValues,
  getPostLoginData,
  getProfilingInfo,
  isArray,
  isJsonString,
  jsonParse,
  jsonStringify,
  markDownTextToJSX,
  sortListOnSpecificKeyValue,
  sortObjectsArrayByKey,
  uniqByKey,
} from "../../utils";
import { TagsetTagType } from "../parser.types";
import { getChatPrefrencesData } from "../../utils/getchatprefrencesdata";
import { MOMENT_DATE_FORMATS } from "../../constants";
import { getUserQrysPrefrOfAnsOutput } from "../../pages/analysisdetailpage/analisisdetailpage.utils";
// <--- Utils End --->

import { ANL_DEF_PAR_KEYS } from "./analysisparser.constants";

export function getParsedAnalysis({
  data,
}: AxiosResponse<ChatBotsResponseType[]>): ReturnTypeOfChatBots[] {
  return (
    data
      ?.map((item) => {
        const users: UserType[] = jsonParse(
          item?.ANALYSIS_USERS || ""
        ) as UserType[];

        const queryTables: TableType[] = jsonParse(item?.DA_TABLES || "");
        return {
          id: item?.DA_ID || 0,
          name: item?.DA_NAME || "",
          description: item?.DA_DESC || "",
          shared_level_id: item?.DA_SHARE_LEVEL_ID || "ALL",
          created_by: item?.CREATED_BY || "",
          refreshed_on: item?.DA_LAST_EXEC_ON || "",
          query_count: item?.DA_QUERY_CNT || 0,
          tables: queryTables?.map((item) => item?.anl_tbl_name) || [],
          author_email: item?.AUTHOR_EMAIL || "",
          analysis_users: users?.map(
            (user) =>
              ({
                email: user?.USER_EMAIL || "",
                accessType: user?.NODE_REL_TYPE || "USER_EDITOR",
                type: user?.USER_TYPE || "USR",
                id: `${user?.USER_ID || ""}`,
              } || "")
          ),
          datasource_used: {
            name: item?.SRC_NAME || "",
            type: item?.SRC_TYPE || "ADL",
          },
        };
      })
      ?.sort((a, b) =>
        a.refreshed_on > b.refreshed_on
          ? -1
          : b.refreshed_on > a.refreshed_on
          ? 1
          : 0
      ) || []
  );
}

export function getParsedAnalysisDocForRefPage({
  data,
}: AxiosResponse<AnalysisDocResponse>): ReturnTypeOfAnalysisRefParser {
  const tablesUsed = data?.tables?.value || [];

  return {
    name: data?.node_name || "",
    share_level_id: data?.da_access?.value?.da_share_level_id || "ALL",
    last_refreshed_on: data?.da_exec_dtls?.value?.last_executed_on || "",
    overview_info: [
      {
        title: ANALYSIS_SECTION_TITLES?.overview?.description,
        value: data?.node_desc || "",
      },
      {
        title: ANALYSIS_SECTION_TITLES?.overview?.authors,
        value: data?.author?.user_name || "",
      },
      {
        title: ANALYSIS_SECTION_TITLES?.overview?.total_queries,
        value: `${data?.da_queries_cnt || 0}`,
      },
      {
        title: ANALYSIS_SECTION_TITLES?.overview?.tables_used,
        value: JSON.stringify(tablesUsed),
      },
    ],
  };
}

export function getParsedAnalysisDocForDetailPage({
  data,
}: AxiosResponse<AnalysisDetailsDocResponse>): ReturnTypeOfAnalysisDetailPageParser {
  return {
    table_id: `${data?.def_tbl_id?.value || ""}`,
    src_id: `${data?.parent_id || ""}`,
    header: {
      title: data?.da_name?.value || "",
      desc: data?.description?.value || "",
      view: {
        is_collapse: false,
        is_expanded: false,
      },
      actions: {
        is_expanded: false,
      },
    },
    pinnedTables:
      data?.pinned_tables?.value?.map((table) => ({
        id: table?.pin_tbl_id || 0,
        //for previously existing analysis where pin_tbl_title property doesn't exists
        title: table?.pin_tbl_title || table?.pin_tbl_name || "",
        name: table?.pin_tbl_name || "",
      })) || [],
    configuration: {
      prompt_inputs: {
        ai_assistant_type:
          data?.da_props?.value?.prompt_inputs?.ai_assistant_type || "",
        guidelines: data?.da_props?.value?.prompt_inputs?.guidelines || "",
        data_description:
          data?.da_props?.value?.prompt_inputs?.data_description || "",
        logic: data?.da_props?.value?.prompt_inputs?.logic || "",
        response_format:
          data?.da_props?.value?.prompt_inputs?.response_format || "",
      },
      workflow_json_s3_pre_signed_get_url:
        data?.da_props?.value?.workflow_json_s3_pre_signed_get_url || "",
    },
    analysis_info: {
      is_node_cloned: false,
      parent_analysis_id: 0,
      analysis_id: data?.node_id || 0,
      created_by: {
        name: data?.author?.user_name || "",
        email: data?.author?.user_email || "",
      },
      share_type: data?.da_access?.value?.da_share_level_id || "NON",
      shared_with:
        data?.da_shared_with?.value?.map((user) => ({
          name: user?.USER_NAME || "",
          type: user?.USER_TYPE,
          email: user?.USER_EMAIL || "",
          accessType: user?.NODE_REL_TYPE || "USER_VIEWER",
          id: `${user?.USER_ID || ""}`,
        })) || [],
      tables_list:
        data?.tables?.value
          ?.map((table) => table?.anl_tbl_name)
          ?.filter((filTbl) => filTbl) || [],
      datasource_used: {
        name: data?.parent_name || "",
        type: data?.src_type_id,
      },
      selected_contexts: isJsonString(data?.da_props?.value?.da_context)
        ? jsonParse(data?.da_props?.value?.da_context)?.filter(
            (item: ContextType) => !!item?.title
          )
        : (data?.da_props?.value?.da_context || "")
            ?.split(",")
            ?.filter((item) => !!item)
            ?.map((item) => ({
              title: item,
              id: createUUID(),
              selected: true,
              parent_id: "",
            })),
      selected_topic_id: data?.da_props?.value?.ref_node_id || 0,
    },
    is_scope_enabled: data?.is_scope_enabled?.value || false,
    train_bot_filter: "all",
    asked_by_filter: ["all"],
    time_frame_filter: "all",
    tableFilter: data?.da_filter?.value?.TBL?.readableFilter || [],
    columnFilter: data?.da_filter?.value?.COL?.readableFilter || [],
    is_verbose: false,
    aiChatModel: data?.da_props?.value?.llm_provider || "",
    aiChatModelName: data?.da_props?.value?.llm_provider_name || "",
    gatewayId: `${data?.saws_id}`,
  };
}

export function getParsedDatasets({
  data,
}: AxiosResponse<Array<DatasetsResponseType>>): ReturnTypeOfAllDatasets {
  const schemasList: ReturnTypeOfAllDatasets = [];

  for (let i = 0; i < data?.length; i++) {
    const tableItem = data[i];
    const schemaIndex = schemasList?.findIndex(
      (item) => item?.title === tableItem?.FOLDER_NAME
    );

    if (schemaIndex >= 0) {
      schemasList[schemaIndex].tables.push({
        id: tableItem?.NODE_ID || 0,
        title: tableItem?.NODE_TITLE || "",
        isPinned: false,
        name: tableItem?.NODE_NAME || "",
      });
    } else {
      schemasList.push({
        title: tableItem?.FOLDER_NAME || "",
        tables: [
          {
            id: tableItem?.NODE_ID || 0,
            title: tableItem?.NODE_TITLE || "",
            isPinned: false,
            name: tableItem?.NODE_NAME || "",
          },
        ],
      });
    }
  }

  return schemasList || [];
}

export function getParsedAnalysisTopicsList({
  data = [],
}: AxiosResponse<AnalysisTopicsListResponseType>): ReturnTypeOfAnalysisTopicsList {
  return (
    data
      ?.sort(
        (a, b) =>
          new Date(b?.UPDATED_ON).getTime() - new Date(a?.UPDATED_ON).getTime()
      )
      ?.map((item) => ({
        id: item?.DA_ID || 0,
        title: item?.DA_NAME || "",
        source_id: item?.SRC_ID || 0,
        source_name: item?.SRC_NAME || "",
        source_type_id: item?.SRC_TYPE || "",
        gatewayId: `${item?.SAWS_ID || ""}`,
      })) || []
  );
}

export function getParsedChatList({
  data,
}: AxiosResponse<ChatListResponseType>): ReturnTypeOfChatList {
  return (
    data?.map((item) => ({
      chat_id: item?.DA_ID || 0,
      chat_name: item?.DA_NAME || "",
    })) || []
  );
}

export const parseFieldsOfPinnedTables = ({
  data = [],
}: AxiosResponse<PinnedTablesFieldsRes>): ReturnTypeOfParseFieldsOfTablesThatIncludesInPrompt[] => {
  const mappedColsWithTable: {
    [key: string]: ReturnTypeOfParseFieldsOfTablesThatIncludesInPrompt;
  } = data?.reduce(
    (
      prev: {
        [key: string]: ReturnTypeOfParseFieldsOfTablesThatIncludesInPrompt;
      },
      next
    ) => {
      const prevItem = prev?.[`${next?.TBL_ID || ""}`];
      const promTags = jsonParse(next?.PROMINENT_TAGS) as TagsetTagType[];

      const isFieldContainsPrivacyInfo = promTags?.some(
        (pTag) => pTag?.tag_privacy === "MSK" || pTag?.tag_privacy === "NOA"
      );

      const profilingInfo = getProfilingInfo(
        next?.COL_RECORD_CNT,
        next?.COL_DIST_CNT,
        next?.COL_BLANKS_CNT
      );

      const item: ReturnTypeOfParseFieldsOfTablesThatIncludesInPrompt = {
        table_id: `${next?.TBL_ID || ""}`,
        table_name: next?.TBL_NAME || "",
        table_title: next?.TBL_TITLE || "",
        cols_to_select: next?.IS_SELECTED
          ? Number(next?.COLS_CNT || 0)
          : Number(prevItem?.cols_to_select || 0),
        columns: [
          ...(prevItem?.columns || []),
          {
            col_name: next?.COL_NAME || "",
            col_key:
              `${next?.TBL_NAME || ""} ${
                next?.COL_TITLE || next?.COL_NAME || ""
              }` || "",
            col_display_name: next?.COL_TITLE || next?.COL_NAME || "",
            data_type_id: next?.COL_DATA_TYPE_ID,
            is_selected: isFieldContainsPrivacyInfo
              ? false
              : !!next?.IS_SELECTED,
            is_default: isFieldContainsPrivacyInfo ? false : !!next?.IS_DEFAULT,
            is_key_col: isFieldContainsPrivacyInfo ? false : !!next?.IS_KEY_COL,
            col_id: `${next?.COL_ID || ""}`,
            is_privacy_applied: isFieldContainsPrivacyInfo,
            is_chat_disabled: !next?.IS_CHAT_ENABLED,
            profilingInfo,
          },
        ],
      };
      return {
        ...prev,
        [`${next?.TBL_ID || ""}`]: item,
      };
    },
    {}
  );

  const objValues: ReturnTypeOfParseFieldsOfTablesThatIncludesInPrompt[] = getObjectValues(
    mappedColsWithTable
  );

  return objValues?.map((tbl) => ({
    ...tbl,
    columns: sortListOnSpecificKeyValue<
      ReturnTypeOfParseFieldsOfTablesThatIncludesInPrompt["columns"][number]
    >({ list: tbl?.columns, key: "col_display_name" }),
  }));
};

export const getParsedUserPreferences = ({
  data,
}: AxiosResponse<UserPreferencesResponseType>): ReturnTypeOfUserPreferencesParser => {
  const dateFormat =
    typeof data?.date_format === "string"
      ? data?.date_format
      : data?.date_format?.format;

  return {
    ...data,
    default_answer_view: data?.default_answer_view || "chart",
    is_descriptive_mode_enabled: data?.is_descriptive_mode_enabled ?? true,
    collapse_view: data?.collapse_view || "default_view",
    date_format: dateFormat || MOMENT_DATE_FORMATS?.["MM/DD/YYYY"],
    metric_format: {
      currency_cols_list: data?.metric_format?.currency_cols_list || [],
      percentage_cols_list: data?.metric_format?.percentage_cols_list || [],
      cols_to_ignored_dvsum_defaut_logic:
        data?.metric_format?.cols_to_ignored_dvsum_defaut_logic || [],
    },
  };
};

const listtoCheck = [
  "total modem count",
  "total modem with issues count",
  "total modem with issues pct",
  "total modem flapped simultaneously count",
  "total modem flapped simultaneously pct",
];

export const getParsedQueryResults = (res: AxiosResponse<any>): Array<any> => {
  try {
    const resData = res?.data || [];

    const isArrayResponse = isArray(resData);
    const isJsonResponse = isJsonString(jsonStringify(resData));

    if (isArrayResponse) return resData;
    if (isJsonResponse) {
      const flattenObject = flattenObjects(resData);
      const mappedData = getObjectEntries(flattenObject)
        ?.map((item) => {
          const [key, value] = item;
          const trailingProperty = key?.split(".")?.slice(-1)?.join(".");
          const secondLastTrailingProperty =
            key?.split(".")?.slice(-2)?.[0] || "";

          const regexPattern = /value/i;
          const isValKeyword = regexPattern.test(trailingProperty);

          return {
            [ANL_DEF_PAR_KEYS.PROPERTY]: isValKeyword
              ? secondLastTrailingProperty || trailingProperty
              : trailingProperty,
            [ANL_DEF_PAR_KEYS.VALUE]: value,
            Details: key,
          };
        })
        ?.filter((mapItem) => {
          const regexPattern = /type/i;
          return !regexPattern.test(mapItem?.Property);
        });

      const sortedData = sortListOnSpecificKeyValue({
        list: mappedData,
        key: ANL_DEF_PAR_KEYS.PROPERTY,
        type: "caseInsensitive",
      });

      const dataToShowTop = listtoCheck
        ?.map((item) =>
          sortedData?.filter(
            (nestItem) =>
              nestItem?.Property?.toLowerCase().replace(/_/g, " ") === item
          )
        )
        ?.flat();

      const dataToShowEnd = sortedData?.filter(
        (item) =>
          !listtoCheck?.includes(
            item?.Property?.toLowerCase().replace(/_/g, " ")
          )
      );

      return [...dataToShowTop, ...dataToShowEnd];
    }

    return [];
  } catch {
    return [];
  }
};

export const getParsedBotConcepts = (
  { data = [] }: AxiosResponse<BotConceptsResponseType>,
  nodeId: string,
  sourceId: string
): ReturnTypeOfBotConceptsParser => {
  return data?.map((item) => ({
    id: `${item?.CNP_ID || 0}`,
    guideline: item?.CNP_TEXT || "",
    updated_by: item?.UPDATED_BY || "",
    updated_on: item?.UPDATED_ON || "",
    type: "concept",
    concept_level: item?.CNP_LEVEL_ID || "BOT",
    action_rend: "",
    analysis_id: `${item?.DA_ID || 0}`,
    source_id: `${item?.SRC_ID}`,
    source_name: item?.SRC_NAME || "",
    currentBotId: `${nodeId}`,
    currentBotSourceId: `${sourceId}`,
  }));
};

export const getParsedAnalysisQueries = ({
  data = [],
}: AxiosResponse<AnalysisQueriesResponseType>): ReturnTypeOfAnalysisQueriesParser => {
  const { user_info: userInfo } = getPostLoginData();
  const {
    user_name: userName = "",
    user_first_name: userFirstName = "",
    user_last_name: userLastName = "",
  } = userInfo || {};

  const sortedData = sortListOnSpecificKeyValue({
    list: data,
    key: "CREATED_ON",
    sortAscending: true,
    type: "date",
  });

  const flattenData: AnalysisQueriesResponseType = sortedData
    ?.map((dataItem) => {
      const queryDetails: AnalysisQueryDetailResponseType = jsonParse(
        dataItem?.QRY_DETAILS || ""
      );

      //On BE children queries save inside the parent
      //query but on FE we deal the nested queries also at the same level.
      if (queryDetails?.sub_queries?.length) {
        return [
          dataItem,
          ...queryDetails?.sub_queries?.map((subQry) => ({
            ...dataItem,
            QRY_DETAILS: jsonStringify(subQry),
          })),
        ];
      }
      return dataItem;
    })
    ?.flat();

  const parsedResults =
    flattenData?.map((query) => {
      const { isRestAPISource } = checkSourceType(query?.SRC_TYPE_ID);
      const queryDetails: AnalysisQueryDetailResponseType = jsonParse(
        query?.QRY_DETAILS || ""
      );
      // will enable when total records limit increased from 10000
      // const isServerSidePag =
      //   queryDetails?.total_records > MAX_LIMIT_OF_CLIENT_SIDE_FILT;

      const isServerSidePag = false;

      const queryContext: ContextType[] = isJsonString(queryDetails?.da_context)
        ? jsonParse(queryDetails?.da_context)?.filter(
            (item: ContextType) => !!item?.title
          )
        : (queryDetails?.da_context || "")
            ?.split(",")
            ?.filter((item) => !!item)
            ?.map((item) => ({
              title: item,
              id: createUUID(),
              selected: true,
              parent_id: queryDetails?.qry_id,
            }));

      // will remove this check shouldHandlePrevChartConfigVersion in next to next release just added to handle previous version of data
      const key: keyof AnalysisPageQueryBoxChartConfig = "chart_y_axises";

      const queryChartConfig = queryDetails?.chart_config || {
        is_default_settings: true,
      };

      const doesChartConfigContainsLatestKey = key in queryChartConfig;
      const shouldHandlePrevChartConfigVersion = !doesChartConfigContainsLatestKey;

      const updatedChartConfig: AnalysisPageQueryBoxChartConfig = shouldHandlePrevChartConfigVersion
        ? {
            ...queryChartConfig,
            chart_y_axises: [
              {
                name: queryChartConfig?.chart_y_axis || "",
                data_type: queryChartConfig?.chart_y_axis_data_type || "NUM",
                order: 1,
                hide: !!queryChartConfig?.is_hide_chart_y_axis,
                display_name: queryChartConfig?.chart_y_axis || "",
                orientation: queryChartConfig?.chart_y_axis_orientation,
              },
              {
                name: queryChartConfig?.chart_y2_axis || "",
                data_type: queryChartConfig?.chart_y2_axis_data_type || "NUM",
                order: 2,
                hide: !!queryChartConfig?.is_hide_chart_y2_axis,
                display_name: queryChartConfig?.chart_y2_axis || "",
                orientation: queryChartConfig?.chart_y2_axis_orientation,
              },
            ]?.filter((axis) => axis?.name),
          }
        : queryChartConfig;

      const {
        qrys_pref_show_code: qrysPrefShowCode = {},
        qrys_pref_show_expl: qrysPrefShowExpl = {},
        qrys_pref_show_logs: qrysPrefShowLogs = {},
      } = getUserQrysPrefrOfAnsOutput();

      const {
        show_code: showCode = false,
        show_explanation: showExplanation = false,
        show_log: showLog = false,
      } = getChatPrefrencesData();

      const updatedQuery: AnalysisPageQueryBox = {
        qry_sec_id: `${query?.QRY_ID || 0}`,
        is_newly_added_question: false,
        is_selected: false,
        type: queryDetails?.qry_type || "NCQ",
        sub_type: queryDetails?.qry_sub_type,
        src_id: `${query?.SRC_ID || ""}`,
        src_name: query?.SRC_NAME || "",
        selected_topic_id: queryDetails?.ref_node_id || 0,
        table_id: `${queryDetails?.qry_props?.qry_tbls?.tbl_id || 0}`,
        table_name: queryDetails?.qry_props?.qry_tbls?.tbl_name || "",
        qry_created_by: queryDetails?.qry_created_by || userName || "",
        qry_created_by_first_name:
          queryDetails?.qry_created_by_first_name || userFirstName || "",
        qry_created_by_last_name:
          queryDetails?.qry_created_by_last_name || userLastName || "",
        qry_created_by_id: queryDetails?.qry_created_by_id || 0,
        qry_created_on: queryDetails?.qry_created_on || "",
        qry_exec_by: queryDetails?.qry_exec_by || "",
        qry_exec_by_id: queryDetails?.qry_exec_by_id || 0,
        qry_exec_on: queryDetails?.qry_exec_on || "",
        qry_updated_by:
          query?.UPDATED_BY ||
          queryDetails?.qry_updated_by ||
          queryDetails?.qry_created_by ||
          "",
        qry_updated_by_id:
          query?.UPDATED_BY_ID ||
          queryDetails?.qry_updated_by_id ||
          queryDetails?.qry_created_by_id ||
          0,
        qry_updated_on:
          query?.UPDATED_ON ||
          queryDetails?.qry_updated_on ||
          queryDetails?.qry_created_on ||
          "",
        qry_prf_results_s3_url: queryDetails?.qry_prf_results_s3_url || "",
        loading_step: 3,
        qry_tmp_results_s3_url: isServerSidePag
          ? ""
          : queryDetails?.qry_tmp_results_s3_url || "",
        qry_full_results_s3_url: queryDetails?.qry_full_results_s3_url || "",
        verbose_s3_url: queryDetails?.verbose_s3_url || "",
        is_loading_verbose_logs: !!queryDetails?.verbose_s3_url,
        is_loading: false,
        error: queryDetails?.error,
        loader_type: undefined,
        id: queryDetails?.qry_id || "",
        parent_query_id: queryDetails?.parent_query_id || "",
        is_expanded: checkIsViewOnlyChatQuestionPage()
          ? true
          : !!queryDetails?.is_expanded,
        is_focused: false,
        use_du_tool: !!queryDetails?.use_du_tool,
        use_general_knowledge_tool: !!queryDetails?.use_general_knowledge_tool,
        use_sql_tool: !!queryDetails?.use_sql_tool,
        header: {
          title: queryDetails?.qry_name || "",
          is_maximize_query_block: false,
          is_profiled: false,
          is_chart_settings_visible: false,
          is_editing_query_mode: false,
          edited_query_value: queryDetails?.qry_name || "",
          is_show_contexts_list: false,
          is_use_context: queryDetails?.use_context || false,
          query_context: queryContext,
          result_type: queryDetails?.result_type || "chart",
          is_sql_query_visible:
            qrysPrefShowCode?.[queryDetails?.qry_id] ?? showCode,
          is_verbose_visible:
            qrysPrefShowLogs?.[queryDetails?.qry_id] ?? showLog,
          is_explanation_visible:
            qrysPrefShowExpl?.[queryDetails?.qry_id] ?? showExplanation,
          is_question_saved: !!queryDetails?.is_question_saved,
        },
        query: {
          sql_query: queryDetails?.qry_text || "",
          is_filter_expanded: false,
          is_filter_edit_mode_expanded: false,
          filters: jsonParse(
            queryDetails?.filtersForUI,
            true
          ) as ResponseQueryFiltersForUIType,
        },
        filters: {
          applied_filters:
            queryDetails?.qry_type === "CCQ"
              ? {}
              : (jsonParse(
                  queryDetails?.filtersForUI,
                  true
                ) as ResponseQueryFiltersForUIType),
          transiant_filters: {},
          sortModel: queryDetails?.sort_model || [],
        },
        results: {
          last_refreshed: queryDetails?.qry_exec_on || "",
          data: [],
          columns: [],
          columns_profilig: [],
          is_server_side_filteration: isServerSidePag,
          record_count: queryDetails?.total_records ?? 0,
          grid_record_count: queryDetails?.total_records ?? 0,
          pagination: DEFAULT_QUERY_BLOCK_GRID_PAGINATION,
          tbl_record_count: queryDetails?.tbl_record_count ?? 0,
          is_sample_data: !!queryDetails?.is_sample_data,
          is_caddi_sample_data: queryDetails?.is_caddi_sample_data,
          summary: queryDetails?.summary || "",
          guide_me_data: queryDetails?.guide_me_data,
          summary_response_keys: queryDetails?.summary_response_keys || [],
          prompt_summary: queryDetails?.prompt_summary,
          tables_used_in_answer:
            typeof queryDetails?.tables_used_in_answer === "object"
              ? queryDetails?.tables_used_in_answer || []
              : jsonParse(queryDetails?.tables_used_in_answer),
          query_explanation: isJsonString(queryDetails?.query_explanation)
            ? jsonParse(queryDetails?.query_explanation)
            : queryDetails?.query_explanation || [],
          questiont_answer: queryDetails?.questiont_answer || "",
          formatted_answer: markDownTextToJSX(
            queryDetails?.questiont_answer || ""
          ),
          chart_config: updatedChartConfig || {},
          pivot_state: jsonParse(queryDetails?.pivot_state),
          verbose_response: queryDetails?.verbose_response || [],
          sec_title: queryDetails?.qry_result_sec_title || "",
          ...(isRestAPISource && {
            columns: queryDetails?.qry_cols || [],
            columns_profilig: (queryDetails?.qry_cols || [])?.map((col) => ({
              ...col,
              profiling: [],
              is_endorsed: false,
              endorsed_by: "",
              chart_data: [],
            })),
          }),
        },
        qry_status: queryDetails?.qry_status || "",
        qry_retry_count: 0,
        user_feedback: {
          reason: queryDetails?.qry_reason_cd || "",
          response:
            queryDetails?.is_helpful === null || queryDetails?.parent_query_id
              ? ""
              : queryDetails?.is_helpful
              ? "like"
              : "dislike",
          comment: queryDetails?.qry_comment || "",
          train_me: queryDetails?.qry_train_me || "",
          train_sql: queryDetails?.qry_train_sql || "",
        },

        settings: {
          ...queryDetails?.settings,
          is_data_formatted: !!queryDetails?.settings?.is_data_formatted,
          date_format: getChatPrefrencesData()?.date_format,
          metric_format: getChatPrefrencesData()?.metric_format,
        },
        rephrased_question:
          queryDetails?.rephrased_question || queryDetails?.qry_name || "",
      };
      return updatedQuery;
    }) || [];

  return uniqByKey(parsedResults, "id");
};

export const getParsedReviewCaddiFeedbacksList = ({
  data = [],
}: AxiosResponse<ReviewCaddiFeedbacksListResponseType>): ReturnTypeOfReviewCaddiFeedbacksListParser => {
  return data
    ?.sort(
      (a, b) =>
        new Date(b?.FDBK_UPDATED_ON)?.getTime() -
        new Date(a.FDBK_UPDATED_ON)?.getTime()
    )
    ?.map((item) => ({
      feedbackType: item?.FEEDBACK_TYPE,
      feedbackText: item?.QRY_FEEDBACK || "",
      qry_tain_text: item?.QRY_TRAIN_TEXT || "",
      question: item?.QRY_NAME || "",
      chat_time: item?.FDBK_UPDATED_ON || "",
      status: item?.QRY_FDBK_STATUS || "",
      status_id: item?.QRY_FDBK_STATUS_ID || "NEW",
      chat_name: item?.CHT_NAME || "",
      chat_id: `${item?.CHT_ID || 0}`,
      query_id: `${item?.QRY_ID || 0}`,
      user: item?.UPDATED_BY || "",
      chat_author: item?.CHAT_AUTHOR || "",
    }));
};
