import { useCallback, useMemo } from "react";

import { FormProvider, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

import Dynamicform from "../../dynamicform";
import { addCompareMetricSchema } from "../../../../utils/schemas";

import { AddCompareMetricFormType } from "./addcomparemetricform.types";
import { addCompareMetricFormConfig } from "./addcomparemetricform.config";

import { GetAddRuleFormContentProps } from "../../addruleform.types";
import { FiltersColumnParsedConfigurationType } from "../../../../parsers/tablepage/tablepageparser.types";

import { useGetRefByType } from "../../../../api/refservice";
import { filterCriteriaApiData } from "../../../addgovernanceviewform/addgovernanceviewform.utils";

import {
  getChatPrefrencesData,
  sortObjectsArrayByKey,
} from "../../../../utils";
import { useGetColumnsWithDistribution } from "../../../../api/tablesservice";
import { useGetReferneceTablesList } from "../../../../api/erddiagramservice/erddiagramservice";

const AddCompareMetricForm = (
  props: GetAddRuleFormContentProps
): JSX.Element => {
  const {
    columnSelectOptions,
    sourcesList,
    columnsData,
    tableId,
    sourceId,
    onAddRule,
    onUpdateRule,
    ruleCategoryId,
    formId,
    metricTypes = [],
    propsOnCancel,
    ruleConfig,
    isEdit = false,
  } = props;

  const initialFilters = ruleConfig?.filter_cond;

  const initialRefFilters = ruleConfig?.ref_filter_cond;

  const { parsedData: parsedCompareMetricTypes } = useGetRefByType(
    "METRIC_COMPARE_TYPES"
  );

  const sortedParsedCompareMetricTypes = useMemo(
    () => sortObjectsArrayByKey(parsedCompareMetricTypes, "name"),
    [parsedCompareMetricTypes]
  );

  const sortedMetricColumnOptions = useMemo(() => {
    const metricColumns =
      (columnsData as FiltersColumnParsedConfigurationType[])?.filter(
        (option) =>
          option?.field_datatype === "INT" || option?.field_datatype === "DEC"
      ) || [];

    return metricColumns?.map(
      (col) =>
        ({
          key: `metric-type-option-${col?.field_id}`,
          value: `${col?.field_id}`,
          label: col?.field_display_name || "",
          type: col?.field_datatype || "",
        } || [])
    );
  }, [columnsData]);

  const {
    is_descriptive_mode_enabled: isDescriptiveModeEnabled,
  } = getChatPrefrencesData();

  const parsedCompareMetricTypesOptions = useMemo(
    () =>
      sortedParsedCompareMetricTypes?.map(
        (compareMetricType) =>
          ({
            key: `compare-metric-option-${compareMetricType?.id}`,
            value: compareMetricType?.id,
            label: compareMetricType?.name || "",
            labelDesc: isDescriptiveModeEnabled
              ? compareMetricType?.description || ""
              : "",
          } || [])
      ),
    [sortedParsedCompareMetricTypes]
  );

  const parsedMetricTypesOptions = useMemo(
    () =>
      metricTypes?.map(
        (metric) =>
          ({
            key: `metric-type-option-${metric?.id}`,
            value: `${metric?.id}` || "",
            label: metric?.name || "",
          } || [])
      ),
    [metricTypes]
  );

  const {
    col_id: colId,
    rule_desc: desc = "",
    col_ids: colIds,
    ref_src_id: refSrcId = "",
    ref_tbl_id: refTblId = "",
    ref_col_ids: refColIds,
    metric_type: initialMetricType = "CNT",
    variance_pct: variancePct = 0,
    attr_col_ids: attrColIds = [],
    compare_type: compareType = "FUL",
    ref_attr_col_ids: refAttrColIds = [],
    metric_col_ids: metricColIds = [],
    ref_metric_col_ids: refMetricColIds = [],
  } = ruleConfig || {};

  const addCompareMetricForm = useForm<AddCompareMetricFormType>({
    defaultValues: {
      id: "",
      rule_type: "Compare Metric",
      rule_description: desc,
      metric_type: initialMetricType,
      variance_percent: variancePct,
      columns: attrColIds?.map((colId) => String(colId)),
      comparison_type: compareType,
      metrics: metricColIds?.map((colId) => String(colId)),
      reference_columns: refAttrColIds?.map((colId) => String(colId)),
      reference_metric: refMetricColIds?.map((colId) => String(colId)),
      reference_source: String(refSrcId),
      reference_table: String(refTblId),

      is_filter_criteria_active: isEdit,
      filter_criteria: [],
      reference_filter_criteria: [],
    },
    resolver: yupResolver(addCompareMetricSchema),
    mode: "onChange",
  });

  const { watch, setValue } = addCompareMetricForm;
  const {
    metric_type: metricType,
    reference_source: refSource,
    reference_table: refTable,
  } = watch();

  const {
    parsedData: tablesList = [],
    isLoading: isLoadingTables = false,
    error: refrenceTablesListError,
  } = useGetReferneceTablesList(refSource, true);

  const sortedTablesList = useMemo(
    () => sortObjectsArrayByKey(tablesList, "table_name"),
    [tablesList]
  );

  const {
    parsedData: selectedTableColumnsList,
    isLoading: isSelectedTableColumnsListLoading,
  } = useGetColumnsWithDistribution(refTable);

  const onMetricTypeChange = useCallback(() => {
    setValue("reference_source", "");
    setValue("reference_table", "");
    setValue("reference_columns", []);
    setValue("reference_metric", []);
    setValue("columns", []);
  }, []);

  const sourceSelectOptions = useMemo(
    () =>
      sourcesList?.map((source) => ({
        key: `source-option-${source?.id}`,
        value: `${source?.id}`,
        label: source?.name || "",
      })) || [],
    [sourcesList]
  );

  const onSourceChange = useCallback((sourceId: string) => {
    setValue("reference_table", "");
    setValue("reference_columns", []);
    setValue("reference_metric", []);
    // onGetTablesList({ src_id: sourceId });
  }, []);

  const tablesSelectOptions = useMemo(
    () =>
      sortedTablesList?.map((table) => ({
        key: `table-option-${table?.table_id}`,
        value: `${table?.table_id}`,
        label: table?.table_name || "",
      })) || [],
    [sortedTablesList]
  );

  const onTableChange = useCallback((tableId: string) => {
    setValue("reference_columns", []);
    setValue("reference_metric", []);
  }, []);

  const selectedTableFields = useMemo(
    () =>
      selectedTableColumnsList?.map(
        (column: FiltersColumnParsedConfigurationType) => ({
          value: `${column?.field_id}`,
          label: column?.field_display_name || "",
          type: column?.field_datatype,
        })
      ) || [],
    // DEPENDANCIES were [columnsList]
    [selectedTableColumnsList]
  );

  const selectedTableMetricFields = useMemo(() => {
    const metricFields =
      selectedTableColumnsList?.filter(
        (option) =>
          option?.field_datatype === "INT" || option?.field_datatype === "DEC"
      ) || [];

    return (
      metricFields?.map((column: FiltersColumnParsedConfigurationType) => ({
        value: `${column?.field_id}`,
        label: column?.field_display_name || "",
        type: column?.field_datatype || "STR",
      })) || []
    );
  }, [selectedTableColumnsList]);

  const compareMetricConfig = addCompareMetricFormConfig({
    columnSelectOptions,
    sortedMetricColumnOptions,
    sourceSelectOptions,
    tablesSelectOptions: {
      options: tablesSelectOptions,
      isLoading: isLoadingTables,
    },
    selectedTableFields: {
      options: selectedTableFields,
      isLoading: isSelectedTableColumnsListLoading,
    },
    selectedTableMetricFields,
    parsedCompareMetricTypesOptions,
    parsedMetricTypesOptions,
    onTableChange,
    onSourceChange,
    onMetricTypeChange,
    existingFilters: initialFilters,
    existingRefFilters: initialRefFilters,
    isEdit,
  });

  const filteredAddCompareMetricFormSchema = compareMetricConfig?.filter(
    (section) =>
      section?.id?.includes(metricType?.toLocaleLowerCase()) ||
      section?.id === "basic"
  );

  const onSubmit = useCallback(
    (values: AddCompareMetricFormType) => {
      const desc = values?.rule_description;

      const rule_config = {
        metric_type: values?.metric_type,
        attr_col_ids: values?.columns?.map((item) => Number(item)),
        metric_col_ids: values?.metrics?.map((item) => Number(item)),
        ref_src_id: Number(values?.reference_source),
        ref_tbl_id: Number(values?.reference_table),
        ref_attr_col_ids: values?.reference_columns?.map((item) =>
          Number(item)
        ),
        ref_metric_col_ids: values?.reference_metric?.map((item) =>
          Number(item)
        ),
        compare_type: values?.comparison_type,
        variance_pct: values?.variance_percent,
        rule_type: formId,
        rule_desc: desc,
      };

      const formFilterCondition = values?.filter_criteria || [];

      const filterCondition = filterCriteriaApiData(
        formFilterCondition,
        columnsData
      );

      const formReferenceFilterCondition =
        values?.reference_filter_criteria || [];

      const referenceFilterCondition = filterCriteriaApiData(
        formReferenceFilterCondition,
        columnsData
      );

      const selectedColumnIds = values?.columns?.map((column) =>
        Number(column)
      );

      const modifyRulePayload = {
        ...rule_config,
        filter_cond: filterCondition,
        ref_filter_cond: referenceFilterCondition,
        col_ids: selectedColumnIds,
      };

      const addNewRulePayload = {
        rule_cat: ruleCategoryId,
        rule_type: formId,
        rule_desc: desc,
        src_id: sourceId,
        tbl_id: Number(tableId),
        rule_priority: "NRM",
        rule_def_config: rule_config,
        filter: filterCondition,
        col_ids: selectedColumnIds,
        ref_filter: referenceFilterCondition,
      };

      isEdit ? onUpdateRule?.(modifyRulePayload) : onAddRule(addNewRulePayload);
    },
    [tableId, sourceId, columnsData, onAddRule]
  );

  return (
    <FormProvider {...addCompareMetricForm}>
      <Dynamicform
        schema={filteredAddCompareMetricFormSchema}
        onSubmit={onSubmit}
        columnsData={columnsData}
        propsOnCancel={propsOnCancel}
        isEdit={isEdit}
      />
    </FormProvider>
  );
};

export default AddCompareMetricForm;
