import { RadioChangeEvent } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { useCallback, useMemo } from "react";
import { useFormContext } from "react-hook-form";

import {
  CheckboxWithLabelAndDesc,
  CriteriaSelection,
} from "../../../../components";

import { FormItemStyled } from "../../../../components/form";

import { InputField, RadioGroupField } from "../../../../components/formfields";
import TooltipWithIcon from "../../../../components/tooltipwithicon";

import Transfer from "../../../../components/transfer";
import TreeTransfer from "../../../../components/treetransfer";

import { GetAllAccessTypesParserReturnType } from "../../../../parsers/userroles/userrolesparser.types";
import { infoIcon } from "../../../../svgs";
import {
  getPostLoginData,
  getDvSumInformativeText,
  getUserPermissions,
} from "../../../../utils";

import {
  ADD_ROLE_PERMISSIONS_OPTIONS_TOOLTIP,
  ADD_USER_ROLE_CRITERIA_CONFIG,
  ADD_USER_ROLE_FINE_GRAIN_CONFIG,
  ADD_USER_ROLE_TREE_CONFIG,
} from "../../addroleform.constants";

import { AddRoleFormViewStyled } from "../../addroleform.styles";

import {
  AddRoleFormViewProps,
  AddUserRoleFormType,
  PermissionConfigType,
} from "../../addroleform.types";
import { PROVIDE_EXCLUSION_CRITERIA } from "../../../../pages/accountsettingspage/accountsettingspage.views/modulesettings/modulesettingsform/modulesettings.config";
import { SpecifyCriteriaType } from "../../../../components/criteriaselection/criteriaselection.types";
import FormItemLabel from "../../../../components/form/formitemlabel";
import {
  adminAccessTypeDescMapping,
  catalogAccessTypeDescMapping,
  glossaryAccessTypeDescMapping,
} from "../../addroleform.utils";

const AddRoleFormView = (props: AddRoleFormViewProps): JSX.Element => {
  const {
    add_usr_rl_mdl_nm: addUsrRlMdlNm,
    add_usr_rl_mdl_asng_grp: addUsrRlMdlAsngGrp,
  } = getDvSumInformativeText();

  const {
    type = "general",
    parsedAccessTypes,
    isPredefinedRole,
    transferState,
    transferSetState,
    transferUserGroups = [],
    levelOfAccessOptions,
    treeData = [],
    sourceData = [],
    destinationData = [],
    updateTreeData,
    updateSourceData,
    updateDestinationData,
    criteriaParsedFields,
    parsedFilters,
    onResetCriteria,
    isEditMode,
    criteriaFields = [],
  } = props;

  const isSystemCatalogTab = type === "system-catalog";
  const isBusinessGlossaryTab = type === "business-glossary";

  const { user_info: userInfo } = getPostLoginData();
  const { account_tier: accountTier } = userInfo || {};

  const isEnterpriseAccount = accountTier === "ENT";

  const { control, watch, setValue } = useFormContext<AddUserRoleFormType>();

  const selectedAdminAccess = watch("admin_access_type");
  const selectedCatalogAccess = watch("catalog_access_type");
  const selectedGlossaryAccess = watch("glossary_access_type");
  const isAccountAdmin = selectedAdminAccess === "ACT";

  const isShowFineGrainOption =
    (isSystemCatalogTab && ["VWR", "EDT"]?.includes(selectedCatalogAccess)) ||
    (isBusinessGlossaryTab && ["VWR", "EDT"]?.includes(selectedGlossaryAccess));

  const catalogFineGrainEnabled = watch("catalog_fine_grain_enabled");
  const glossaryFineGrainEnabled = watch("glossary_fine_grain_enabled");

  const catalogTableCriteria = watch("catalog_table_criteria");
  const catalogTableCriteriaEnabled = watch("catalog_table_criteria_enabled");

  const glossaryTermCriteria = watch("glossary_term_criteria");
  const glossaryTermCriteriaEnabled = watch("glossary_term_criteria_enabled");

  const isShowCriteriaOption =
    (isSystemCatalogTab && catalogTableCriteriaEnabled) ||
    (isBusinessGlossaryTab && glossaryTermCriteriaEnabled);

  const { is_account_admin: isLoggedInUserAccountAdmin } = getUserPermissions();

  const onAdminAccessTypeChange = useCallback((e: RadioChangeEvent) => {
    if (e?.target?.value === "ACT") {
      setValue("catalog_access_type", "ADM");
      setValue("glossary_access_type", "ADM");
    }
  }, []);

  const permissionsConfig: {
    [key in string]?: PermissionConfigType;
  } = {
    admin: {
      name: "admin_access_type",
      value: selectedAdminAccess,
      options: parsedAccessTypes?.USERROLE_ADMIN?.map(
        (item: GetAllAccessTypesParserReturnType) => ({
          label: item?.displayName,
          labelDesc: adminAccessTypeDescMapping(item?.id),
          value: item?.id,
          key: item?.id,
          disabled: item?.id === "ACT" && !isLoggedInUserAccountAdmin,
          tooltipProps:
            item?.id === "ACT" && !isLoggedInUserAccountAdmin
              ? {
                  title:
                    "Enabled only for users with Account Admin role access.",
                  placement: "right",
                }
              : undefined,
        })
      ),
      disabled: isPredefinedRole,
      onChange: onAdminAccessTypeChange,
    },
    "system-catalog": {
      name: "catalog_access_type",
      value: selectedCatalogAccess,
      options: parsedAccessTypes?.USERROLE_CATALOG?.map(
        (item: GetAllAccessTypesParserReturnType) => {
          const isDisabled =
            ["VWR", "EDT"]?.includes(selectedCatalogAccess) &&
            ["VWR", "EDT"]?.includes(item?.id) &&
            catalogFineGrainEnabled;

          return {
            label: item?.displayName,
            labelDesc: catalogAccessTypeDescMapping(item?.id),
            value: item?.id,
            key: item?.id,
            disabled: isDisabled,
            tooltipProps: isDisabled
              ? ADD_ROLE_PERMISSIONS_OPTIONS_TOOLTIP
              : undefined,
          };
        }
      ),
      disabled: isPredefinedRole || isAccountAdmin,
    },
    "business-glossary": {
      name: "glossary_access_type",
      value: selectedGlossaryAccess,
      options: parsedAccessTypes?.USERROLE_GLOSSARY?.map(
        (item: GetAllAccessTypesParserReturnType) => {
          const isDisabled =
            ["VWR", "EDT"]?.includes(selectedGlossaryAccess) &&
            ["VWR", "EDT"]?.includes(item?.id) &&
            glossaryFineGrainEnabled;
          return {
            label: item?.displayName,
            labelDesc: glossaryAccessTypeDescMapping(item?.id),
            value: item?.id,
            key: item?.id,
            disabled: isDisabled,
            tooltipProps: isDisabled
              ? ADD_ROLE_PERMISSIONS_OPTIONS_TOOLTIP
              : undefined,
          };
        }
      ),
      disabled: isPredefinedRole || isAccountAdmin,
    },
  };

  const isTermCriteriaExists = useMemo(
    () =>
      glossaryTermCriteria?.some((item: SpecifyCriteriaType) => item?.column),
    [glossaryTermCriteria]
  );

  const isTableCriteriaExists = useMemo(
    () =>
      catalogTableCriteria?.some((item: SpecifyCriteriaType) => item?.column),
    [catalogTableCriteria]
  );

  const showExclusionCriteriaNotification = useMemo(
    () =>
      (isSystemCatalogTab &&
        !isTableCriteriaExists &&
        catalogTableCriteriaEnabled) ||
      (isBusinessGlossaryTab &&
        !isTermCriteriaExists &&
        glossaryTermCriteriaEnabled),
    [
      isTableCriteriaExists,
      isTermCriteriaExists,
      glossaryTermCriteriaEnabled,
      catalogTableCriteriaEnabled,
    ]
  );

  return (
    <AddRoleFormViewStyled>
      {type === "general" && (
        <div className="general-tab">
          <FormItemLabel
            label={addUsrRlMdlNm?.field_name || ""}
            description={addUsrRlMdlNm?.description || ""}
          >
            <InputField
              control={control}
              name="name"
              disabled={isPredefinedRole}
            />
          </FormItemLabel>
          <FormItemLabel
            style={{ paddingTop: "6px" }}
            label={addUsrRlMdlAsngGrp?.field_name || ""}
            description={addUsrRlMdlAsngGrp?.description || ""}
          >
            <Transfer
              state={{
                ...transferState,
                data: transferUserGroups,
              }}
              setState={transferSetState}
              leftTile={
                <div className="transfer-left-title">
                  <span>Available</span>
                </div>
              }
              rightTile="Selected"
              showError={false}
            />
          </FormItemLabel>
        </div>
      )}

      {type !== "general" && (
        <FormItemStyled label="Permission">
          <RadioGroupField
            value={permissionsConfig?.[type]?.value as string}
            control={control}
            name={permissionsConfig?.[type]?.name}
            options={permissionsConfig?.[type]?.options!}
            direction="column"
            gap={4}
            disabled={permissionsConfig?.[type]?.disabled}
            {...(permissionsConfig?.[type]?.onChange && {
              propOnChange: permissionsConfig?.[type]?.onChange,
            })}
            labelDescWidth="507px"
          />
        </FormItemStyled>
      )}

      {isShowFineGrainOption && (
        <>
          <CheckboxWithLabelAndDesc
            name={ADD_USER_ROLE_FINE_GRAIN_CONFIG?.[type]?.name || ""}
            label="Fine Grain Control"
            desc={ADD_USER_ROLE_FINE_GRAIN_CONFIG?.[type]?.desc}
            paddingLeft="20px"
            disabled={!isEnterpriseAccount}
          />

          {((isSystemCatalogTab && catalogFineGrainEnabled) ||
            (isBusinessGlossaryTab && glossaryFineGrainEnabled)) && (
            <>
              <FormItemStyled
                label={ADD_USER_ROLE_TREE_CONFIG?.[type]?.label}
                paddingLeft="44px"
              >
                <TreeTransfer
                  sourceTitle="Disabled"
                  destinationTitle="Enabled"
                  enableLevelOfAccess
                  showChildrenCount
                  levelOfAccessOptions={levelOfAccessOptions}
                  treeData={treeData}
                  sourceData={sourceData}
                  destinationData={destinationData}
                  updateTreeData={updateTreeData}
                  updateSourceData={updateSourceData}
                  updateDestinationData={updateDestinationData}
                  leftSideHeight={274}
                  rightSideHeight={334}
                />
              </FormItemStyled>

              <div className="criteria-field">
                <CheckboxWithLabelAndDesc
                  label={ADD_USER_ROLE_CRITERIA_CONFIG?.[type]?.title || ""}
                  desc={ADD_USER_ROLE_CRITERIA_CONFIG?.[type]?.desc}
                  name={ADD_USER_ROLE_CRITERIA_CONFIG?.[type]?.checkboxName}
                  paddingLeft="44px"
                  onChange={(event: CheckboxChangeEvent): void => {
                    const isChecked = event?.target?.checked;
                    !isChecked && onResetCriteria?.(true);
                  }}
                  noteTitle={
                    showExclusionCriteriaNotification
                      ? PROVIDE_EXCLUSION_CRITERIA
                      : ""
                  }
                >
                  {isShowCriteriaOption ? (
                    <CriteriaSelection
                      name={ADD_USER_ROLE_CRITERIA_CONFIG?.[type]?.name}
                      parsedFields={criteriaParsedFields}
                      parsedFilters={parsedFilters}
                      type={ADD_USER_ROLE_CRITERIA_CONFIG?.[type]?.type}
                      onReset={(resetCheckbox?: boolean): void =>
                        onResetCriteria?.(resetCheckbox)
                      }
                      isEditMode={isEditMode}
                      criteriaFields={criteriaFields}
                    />
                  ) : (
                    <div />
                  )}
                </CheckboxWithLabelAndDesc>
              </div>
            </>
          )}
        </>
      )}
    </AddRoleFormViewStyled>
  );
};

export default AddRoleFormView;
