import { useCallback, useMemo } from "react";
import { FormProvider, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";

import Dynamicform from "../../dynamicform";
import { AddOrphanKeysFormType } from "./addorphankeysform.types";

import { addOrphanKeyFormConfig } from "./addorphankeysform.config";
import { addOrphanKeysSchema } from "../../../../utils/schemas";

import { GetAddRuleFormContentProps } from "../../addruleform.types";

import { FiltersColumnParsedConfigurationType } from "../../../../parsers/tablepage/tablepageparser.types";

import { filterCriteriaApiData } from "../../../addgovernanceviewform/addgovernanceviewform.utils";
import {
  sortListOnSpecificKeyValue,
  sortObjectsArrayByKey,
} from "../../../../utils";

import { useGetColumnsWithDistribution } from "../../../../api/tablesservice";
import { useGetReferneceTablesList } from "../../../../api/erddiagramservice/erddiagramservice";

const AddOrphanKeysForm = (props: GetAddRuleFormContentProps): JSX.Element => {
  const {
    columnSelectOptions,
    sourcesList,
    columnsData,
    sourceId,
    tableId,
    onAddRule,
    ruleCategoryId,
    formId,
    propsOnCancel,
    ruleConfig,
    isEdit = false,
    onUpdateRule,
  } = props;

  const initialFilters = ruleConfig?.filter_cond;

  const initialRefFilters = ruleConfig?.ref_filter_cond;

  // const {
  //   onExecuteRequest: onGetTablesList,
  //   data: tablesList = [],
  //   isLoading: isLoadingTables = false,
  //   error: errorInFetchingTables,
  // } = useRequestWithMethod("get_datasets", [], true);

  const {
    rule_desc: desc = "",
    col_ids: colIds,
    ref_src_id: refSrcId = "",
    ref_tbl_id: refTblId = "",
    ref_col_ids: refColIds,
  } = ruleConfig || {};

  const addOrphanKeyForm = useForm<AddOrphanKeysFormType>({
    defaultValues: {
      id: "orphan_keys",
      rule_type: "Orphan Keys",
      rule_description: desc,
      columns: ruleConfig ? colIds?.map((colId) => String(colId)) : [],
      reference_source: String(refSrcId),
      reference_table: String(refTblId),
      reference_columns: ruleConfig
        ? refColIds?.map((colId) => String(colId))
        : [],

      is_filter_criteria_active: isEdit,
      filter_criteria: [],
      reference_filter_criteria: [],
    },
    resolver: yupResolver(addOrphanKeysSchema),
    mode: "onChange",
  });

  const { setValue, watch } = addOrphanKeyForm;

  const { reference_table: refTable, reference_source: refSource } = watch();

  const {
    parsedData: tablesList = [],
    isLoading: isLoadingTables = false,
    error: refrenceTablesListError,
  } = useGetReferneceTablesList(refSource, true);

  const sortedTablesList = useMemo(
    () =>
      sortListOnSpecificKeyValue({
        list: tablesList,
        key: "table_name",
      }),

    [tablesList]
  );

  const {
    parsedData: selectedTableColumnsList,
    isLoading: isSelectedTableColumnsListLoading,
  } = useGetColumnsWithDistribution(refTable);

  const sourceSelectOptions = useMemo(
    () =>
      sourcesList?.map((source) => ({
        key: `source-option-${source?.id}`,
        value: `${source?.id}`,
        label: source?.name || "",
      })) || [],
    [sourcesList]
  );

  const onSourceChange = useCallback((sourceId: string) => {
    setValue("reference_table", "");
    setValue("reference_columns", []);
    // onGetTablesList({ src_id: sourceId });
  }, []);

  const tablesSelectOptions = useMemo(
    () =>
      sortedTablesList?.map((table) => ({
        key: `table-option-${table?.table_id}`,
        value: `${table?.table_id}`,
        label: table?.table_name || "",
      })) || [],
    [sortedTablesList]
  );

  const onTableChange = useCallback((tableId: string) => {
    setValue("reference_columns", []);
  }, []);

  const selectedTableFields = useMemo(
    () =>
      selectedTableColumnsList?.map(
        (column: FiltersColumnParsedConfigurationType) => ({
          value: `${column?.field_id}`,
          label: column?.field_display_name || "",
          type: column?.field_datatype,
        })
      ) || [],
    [selectedTableColumnsList]
  );

  const addaddOrphanKeyFormSchema = addOrphanKeyFormConfig({
    sourceSelectOptions,
    columnSelectOptions,
    tablesSelectOptions: {
      options: tablesSelectOptions,
      isLoading: isLoadingTables,
    },
    selectedTableFields: {
      options: selectedTableFields,
      isLoading: isSelectedTableColumnsListLoading,
    },
    onTableChange,
    onSourceChange,
    existingFilters: initialFilters,
    existingRefFilters: initialRefFilters,
    isEdit,
  });

  const onSubmit = useCallback(
    (values: AddOrphanKeysFormType) => {
      const desc = values?.rule_description;

      const rule_config = {
        col_ids: values?.columns,
        ref_src_id: Number(values?.reference_source),
        ref_tbl_id: Number(values?.reference_table),
        ref_col_ids: values?.reference_columns?.map((item) => Number(item)),
        rule_type: formId,
        rule_desc: desc,
      };

      const formFilterCondition = values?.filter_criteria || [];

      const filterCondition = filterCriteriaApiData(
        formFilterCondition,
        columnsData
      );

      const formReferenceFilterCondition =
        values?.reference_filter_criteria || [];

      const referenceFilterCondition = filterCriteriaApiData(
        formReferenceFilterCondition,
        columnsData
      );

      const selectedColumnIds = values?.columns?.map((column) =>
        Number(column)
      );

      const addNewRulePayload = {
        rule_cat: ruleCategoryId,
        rule_type: formId,
        rule_desc: desc,
        src_id: sourceId,
        tbl_id: Number(tableId),
        rule_priority: "NRM",
        rule_def_config: rule_config,
        col_ids: selectedColumnIds,
        filter: filterCondition,
        ref_filter: referenceFilterCondition,
      };

      const modifyRulePayload = {
        ...rule_config,
        filter_cond: filterCondition,
        ref_filter_cond: referenceFilterCondition,
        col_ids: selectedColumnIds,
      };

      isEdit ? onUpdateRule?.(modifyRulePayload) : onAddRule(addNewRulePayload);
    },
    [tableId, sourceId, columnsData, onAddRule]
  );

  return (
    <FormProvider {...addOrphanKeyForm}>
      <Dynamicform
        schema={addaddOrphanKeyFormSchema}
        onSubmit={onSubmit}
        columnsData={columnsData}
        propsOnCancel={propsOnCancel}
        isEdit={isEdit}
      />
    </FormProvider>
  );
};

export default AddOrphanKeysForm;
