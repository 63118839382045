import { marked } from "marked";
import markedFootnote from "marked-footnote";
import { markedEmoji } from "marked-emoji";
import { highlightTextInMarkdown } from "./highlighttextinmarkdown";

export const markDownTextToJSX = (text: string): any => {
  const results = marked(highlightTextInMarkdown(text), {
    //@ts-ignore
    extensions: [markedFootnote(), markedEmoji({ emojis: [] })],
  });

  return results;
};
